<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产要素清单信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="序号" prop="xh">
                    <el-input
                        v-model="formdata.saveJson.xh"
                        :maxlength="50"
                        placeholder="请输入序号"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <br />
                <el-form-item label="片区" prop="ycysfl_1">
                    <el-select
                        v-model="formdata.saveJson.ycysfl_1"
                        placeholder="请选择片区"
                        :disabled="!isEdit"
                        filterable
                        clearable
                    >
                        <el-option label="古茶林" value="古茶林"></el-option>
                        <el-option
                            label="传统村落"
                            value="传统村落"
                        ></el-option>
                        <el-option
                            label="分隔防护林"
                            value="分隔防护林"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产组成" prop="ycysfl_2">
                    <el-select
                        v-model="formdata.saveJson.ycysfl_2"
                        placeholder="请选择遗产组成"
                        :disabled="!isEdit"
                        filterable
                        clearable
                    >
                        <el-option
                            v-for="(item, index) in yczcList"
                            :key="index"
                            :label="item"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素名称" prop="mc">
                    <el-input
                        v-model="formdata.saveJson.mc"
                        :maxlength="50"
                        placeholder="请输入遗产要素名称"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="遗产要素类型" prop="ycyslx">
                    <el-select
                        v-model="formdata.saveJson.ycyslx"
                        placeholder="请选择遗产要素类型"
                        :disabled="!isEdit"
                        filterable
                        clearable
                        @change="changeYcyslx"
                    >
                        <el-option
                            v-for="(item, index) in ycyslxList"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素描述" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.ycysms"
                        type="textarea"
                        :maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        placeholder="请输入遗产要素描述"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="规模类型">
                    <el-select
                        v-model="formdata.saveJson.gmlx"
                        filterable
                        clearable
                        placeholder="请选择规模类型"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in gmlxList"
                            :key="index"
                            :label="item"
                            :value="item"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="规模大小" prop="gm">
                    <el-input
                        v-model="formdata.saveJson.gm"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        placeholder="请输入规模大小"
                        :disabled="!isEdit"
                    >
                        <template slot="append">㎡</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="经纬度" prop="jwd">
                    <div class="flex">
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="请填写正确的经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者点击“坐标拾取”按钮，直接获取经度坐标"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.saveJson.jwd"
                                placeholder="请输入经纬度"
                                onkeydown="if(event.keyCode==32) return false"
                                :disabled="!isEdit"
                                style="width: 442px; margin-right: 10px"
                            ></el-input>
                        </el-tooltip>
                        <el-button size="small" @click="showPickup = true"
                            >坐标拾取</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="高程" prop="gc">
                    <el-input
                        v-model="formdata.saveJson.gc"
                        :maxlength="50"
                        onkeydown="if(event.keyCode==32) return false"
                        placeholder="请输入高程"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="对应的文保单位" prop="dydwbdw">
                    <el-select
                        v-model="formdata.saveJson.dydwbdw"
                        filterable
                        clearable
                        placeholder="请选择对应的文保单位"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in wbdwList"
                            :key="index"
                            :label="item.DWMC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" class="from-item-block">
                    <el-input
                        v-model="formdata.saveJson.bz"
                        type="textarea"
                        :maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        placeholder="请输入备注"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="照片" style="width: 100%">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="$route.query.ItemID"
                        :metaData.sync="formdata.metaJson"
                        basicType="imgAndVideo"
                    ></basic-upload>
                </el-form-item>
            </el-form>
            <coordinatePickup
                :editState="isEdit"
                :showPickupP.sync="showPickup"
                :coordinate.sync="formdata.saveJson.jwd"
            ></coordinatePickup>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import coordinatePickup from "@comp/map/coordinate_pickup";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "hem_info",
    components: {
        coordinatePickup,
    },
    mixins: [infoMixin],
    data() {
        let checkXH = (rule, value, callback) => {
            let reg = /^[0-9]+$/;
            if (value === "" || !value) {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else if (!reg.test(value)) {
                callback(new Error("请输入正整数"));
            } else {
                callback();
            }
        };
        let checkNum = (rule, value, callback) => {
            if (value === "") {
                callback();
            } else if (isNaN(value)) {
                callback(new Error("请输入数字"));
            } else {
                callback();
            }
        };
        let checkCoordinate = (rule, value, callback) => {
            let reg2 = /^([0-9.]+,)[0-9.]+$/;
            if (value === "" || !value) {
                callback(new Error("请输入经纬度"));
            } else if (!reg2.test(value)) {
                callback(new Error("请输入正确格式的经纬度"));
            } else {
                callback();
            }
        };
        return {
            BASE_URL: window.REQUEST_URL,
            notNeedFile: true,
            wbdwList: [], // 文保单位列表
            showPickup: false,
            snButton: snButton,
            rules: {
                xh: [{ validator: checkXH, trigger: "change" }],
                ycysfl_1: [
                    {
                        required: true,
                        message: "请选择片区",
                        trigger: "change",
                    },
                ],
                ycysfl_2: [
                    {
                        required: true,
                        message: "请输入遗产组成",
                        trigger: "change",
                    },
                ],
                mc: [
                    {
                        required: true,
                        message: "请输入遗产要素名称",
                        trigger: "blur",
                    },
                ],
                ycyslx: [
                    {
                        required: true,
                        message: "请选择遗产要素类型",
                        trigger: "change",
                    },
                ],
                gm: [{ validator: checkNum, trigger: "change" }],
                gc: [{ validator: checkNum, trigger: "change" }],
                jwd: [
                    {
                        required: true,
                        validator: checkCoordinate,
                        trigger: "change",
                    },
                ],
            },
            ycyslxList: [
                {
                    label: "建/构筑物",
                    value: "1",
                    gmlx: ["占地面积", "建筑面积"],
                },
                { label: "遗址/墓葬", value: "2", gmlx: ["占地面积"] },
                { label: "洞窟/龛", value: "3", gmlx: ["分布面积"] },
                {
                    label: "造像/雕塑/碑刻/题刻/壁画/彩画",
                    value: "4",
                    gmlx: ["分布面积"],
                },
                { label: "山体", value: "5", gmlx: ["占地面积"] },
                { label: "水体", value: "6", gmlx: ["占地面积"] },
                { label: "植被", value: "7", gmlx: ["占地面积"] },
                { label: "街区", value: "8", gmlx: ["占地面积"] },
                { label: "其他", value: "9", gmlx: ["占地面积"] },
            ],
            gmlxList: ["占地面积", "建筑面积", "分布面积"],
            formdata: {
                saveJson: {
                    ycysfl_1: "",
                    ycysfl_2: "",
                    shzt: 1,
                    xh: "",
                    mc: "",
                    ycyslx: "",
                    gm: "",
                    gc: "",
                    jwd: "",
                    ycysms: "",
                    gmlx: "",
                    dydwbdw: "",
                    bz: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "7",
                userName: "",
                userId: "",
            },
            // 遗产组成列表
            yczcList: [],
        };
    },
    watch: {},
    mounted() {
        this.getYczcList();
        this.getWBDWList();
    },
    computed: {
        ...mapGetters(["heritageId"]),
    },
    methods: {
        ...mapActions(["getBasicList"]),
        // 获取遗产组成列表
        async getYczcList() {
            this.yczcList = [];
            let result = await this.getBasicList({
                userid: localStorage.userId,
                ycdid: this.heritageId,
                itemId: this.$route.query.ItemID,
                authority: false,
                pageIndex: 1,
                pageSize: 10000,
            });

            let list = [];
            result.ResultValue.gridData.map((item) => {
                list.push(item.YCYSFL_2);
            });
            this.yczcList = Array.from(new Set(list));
        },
        changeYcyslx(val) {
            let ycyslxList = this.ycyslxList.filter((item) => {
                return item.value === val;
            });
            this.gmlxList = ycyslxList[0].gmlx;
            this.formdata.saveJson.gmlx =
                this.gmlxList && this.gmlxList.length === 1
                    ? this.gmlxList[0]
                    : "";
        },
    },
};
</script>
<style lang="scss" scoped></style>
